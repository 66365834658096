<h5 class="header text-center"> Virman Özeti </h5>
<div class="d-flex justify-content-between header-info">
  <span><strong> İşlemi Yapan: </strong> {{response.createrName}}</span>
  <span><strong> Tarih: </strong> {{response.operationDate | date:'dd/MM/yyyy HH:mm'}}</span>
</div>
<div class="d-flex justify-content-between header-info">
  <span><strong> Gönderen: </strong> {{response.sourceCustomerName}} </span>
  <span><strong> Alan: </strong> {{response.destinationCustomerName}} </span>
</div>
<div>
  <table class="table mb-0 mt-3" *ngIf="response.items.length > 0">
    <thead>
      <tr>
        <th class="text-center">ISL</th>
        <th class="text-center">KODU</th>
        <th class="text-center">MİKTAR</th>
        <th class="text-center">MİLYEM</th>
        <th class="text-center">HAS/FİY</th>
        <th class="text-center">TUTAR</th>
        <th class="text-center">BİRİM</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of response.items; let i = index">
        <tr *ngIf="item.type == Type.Debt">
          <td><span *ngIf="item.type == Type.Debt">C</span> <span *ngIf="item.type == Type.Receivable">G</span></td>
          <td class="text-center">{{item.subType | subTypeShort: item.currency}}</td>
          <td class="text-center">{{item.amount | currencyAmount: item.currency: item.subType: true }}</td>
          <td class="text-center">{{item.milyem | receiptMilyem}}</td>
          <td class="text-center">1.000</td>
          <td class="text-center">{{item.amount | currencyAmount: item.currency: item.subType: true }}</td>
          <td class="text-center">{{item.subType | subTypeShort: item.currency}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="text-center m-5" *ngIf="response.items.length == 0">
    <span><i class="fa fa-exclamation-circle"></i> Hesap hareketi bulunamadı.</span>
  </div>

  <ng-container *ngIf="request.includeSourceCustomerBalance">
    <div class="customer-balance-summary mt-3 mb-0" > {{response.sourceCustomerName}} BAKİYE </div>
    <table class="table mb-0 mt-1">
      <thead class="border-bottom">
        <tr>
          <th class="text-center">BİRİM</th>
          <th class="text-center">ÖNCEKİ BAKİYE</th>
          <th class="text-center">GİRİŞ/ÇIKIŞ</th>
          <th class="text-center">SON BAKİYE</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of response.sourceCustomerBalances">
          <td class="text-center">{{item.subType | subTypeShort: item.currency }}</td>
          <td class="text-center">{{item.oldBalance | currencyAmount: item.currency: item.subType: true }}</td>
          <td class="text-center">{{item.operationAmount | currencyAmount: item.currency: item.subType: true }}</td>
          <td class="text-center">{{item.newBalance | currencyAmount: item.currency: item.subType: true }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="request.includeDestinationCustomerBalance">
    <div class="customer-balance-summary mt-3 mb-0" > {{response.destinationCustomerName}} BAKİYE </div>
    <table class="table mb-0 mt-1">
      <thead class="border-bottom">
        <tr>
          <th class="text-center">BİRİM</th>
          <th class="text-center">ÖNCEKİ BAKİYE</th>
          <th class="text-center">GİRİŞ/ÇIKIŞ</th>
          <th class="text-center">SON BAKİYE</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of response.destinationCustomerBalances">
          <td class="text-center">{{item.subType | subTypeShort: item.currency }}</td>
          <td class="text-center">{{item.oldBalance | currencyAmount: item.currency: item.subType: true }}</td>
          <td class="text-center">{{item.operationAmount | currencyAmount: item.currency: item.subType: true }}</td>
          <td class="text-center">{{item.newBalance | currencyAmount: item.currency: item.subType: true }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <p class="sarraf">sarraf.io</p>
  <div class="groupId text-center">Bilgilendirme amaçlıdır.<br> Resmi belge olarak kullanılamaz. Mali değeri yoktur.
  </div>
</div>

<div class="row mt-4"></div>
