import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AccountTransaction } from "src/app/models/account-transaction/account-transaction";
import { Branch } from "src/app/models/branch/branch";
import { Customer } from "src/app/models/customer/customer";
import { VirmanSummaryRequest } from "src/app/models/customer/virman-summary-request";
import { VirmanSummaryResponse } from "src/app/models/customer/virman-summary-response";
import { AccountTransactionReason } from "src/app/models/enums/account-transaction-reason";
import { AccountTransactionType } from "src/app/models/enums/account-transaction-type";
import { AccountTransactionFilter } from "src/app/models/filters/account-transaction-filter";
import { CustomerService } from "src/app/services/customer.service";

@Component({
  selector: "app-virman-summary",
  templateUrl: "./virman-summary.component.html",
  styleUrls: ["./virman-summary.component.scss"],
})
export class VirmanSummaryComponent implements OnInit {
  currentBranch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  request: VirmanSummaryRequest = {
    branchId: this.currentBranch.id,
    groupId: null,
    includeSourceCustomerBalance: true,
    includeDestinationCustomerBalance: false,
  };
  transactions: AccountTransaction[] = [];
  Type = AccountTransactionType;
  Reason = AccountTransactionReason;
  operationDate = new Date();
  customer: Customer = {};
  response: VirmanSummaryResponse = {
    items: []
  };
  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getParams();
    this.getVirmanSummary();
  }

  getParams() {
    this.route.queryParams.subscribe((params) => {
      this.request.groupId = params.groupId;
      this.request.includeSourceCustomerBalance =
        params.includeSourceCustomerBalance == "true" ? true : false;
      this.request.includeDestinationCustomerBalance =
        params.includeDestinationCustomerBalance == "true" ? true : false;
    });
  }

  getVirmanSummary() {
    this.customerService.virmanSummary(this.request).subscribe((res) => {
      this.response = {
        createrName: res.data.createrName,
        branchName: res.data.branchName,
        sourceCustomerName: res.data.sourceCustomerName,
        destinationCustomerName: res.data.destinationCustomerName,
        operationDate: res.data.operationDate,
        items: res.data.items,
        sourceCustomerBalances: res.data.sourceCustomerBalances,
        destinationCustomerBalances: res.data.destinationCustomerBalances,
      };
      setTimeout(() => {
        // window.onafterprint = window.close;
        window.print();
      }, 500);
    });
  }
}
