import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountTransaction } from 'src/app/models/account-transaction/account-transaction';
import { Customer } from 'src/app/models/customer/customer';
import { AccountTransactionType } from 'src/app/models/enums/account-transaction-type';
import { Currency } from 'src/app/models/enums/currency';
import { CollectionSummaryRequest } from 'src/app/models/collection/collection-summary-request';
import { CollectionSummaryResponse } from 'src/app/models/collection/collection-summary-response';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-balance',
  templateUrl: './customer-balance.component.html',
  styleUrls: ['./customer-balance.component.scss']
})
export class CustomerBalanceComponent implements OnInit {
  @Input() customerId?: number;
  currentBranch = JSON.parse(localStorage.getItem('currentBranch'));
  operationDate;
  onlyBalance = true;
  collection: AccountTransaction[] = []
  customer: Customer
  currencyEnum = Currency
  collectionType: string;
  model: CollectionSummaryResponse = {}
  constructor(
    private service: CustomerService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.operationDate = new Date();
    this.route.queryParams.subscribe(params => {
      this.customerId = params.id
      if (params.groupId) {
          this.onlyBalance = false;
            this.getCollectionSummary(params.groupId)
      }
      else{
        this.findCustomer(this.customerId, this.currentBranch.id)
        setTimeout(() => {
          window.onafterprint = window.close;
          window.print();
        }, 500);
        // this.getByLastCollection(params.groupId)
      }
    })
  }



  findCustomer(customerId, branchId){
    this.service.findByBranch(this.customerId, this.currentBranch.id).toPromise().then(res => {
      this.customer = res.data;
    });
  }

  getCollectionSummary(groupId: string){
    let request: CollectionSummaryRequest = {branchId: this.currentBranch.id, customerId: this.customerId, groupId: groupId};
    this.service.collectionSummary(request).toPromise().then(res => {
      this.model = res.data;
      this.customer = {}
      this.collection = res.data.transactions;
      this.customer.name = res.data.displayName;
      if(res.data.transactions.length > 0){
        const isCollection = res.data.transactions[0].type == AccountTransactionType.Receivable;
        this.collectionType = isCollection ? 'Tahsilat' : 'Ödeme';
      }
      setTimeout(() => {
        window.onafterprint = window.close;
        window.print();
      }, 500);
    })
  }

  // getByLastCollection(groupId: string){
  //   this.service.getLastCollection(groupId).toPromise().then(res =>  {
  //     this.collection = res.data;
  //     if(res.data.length > 0){
  //       const isCollection = res.data[0].type == AccountTransactionType.Receivable;
  //       this.collectionType = isCollection ? 'Tahsilat' : 'Ödeme';
  //     }
      // setTimeout(() => {
      //   window.onafterprint = window.close;
      //   window.print();
      // }, 500);
  //   });
  // }


}
