import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IStreamSubscriber } from "@microsoft/signalr";
import { number } from "echarts";
import { Subscriber } from "rxjs";
import { AccountTransaction } from "src/app/models/account-transaction/account-transaction";
import { Branch } from "src/app/models/branch/branch";
import { Customer } from "src/app/models/customer/customer";
import { AccountTransactionReason } from "src/app/models/enums/account-transaction-reason";
import { AccountTransactionType } from "src/app/models/enums/account-transaction-type";
import { ExchangeItem } from "src/app/models/exchange/exchange-item-request";
import { AccountTransactionFilter } from "src/app/models/filters/account-transaction-filter";
import { AccountTransactionService } from "src/app/services/account-transaction.service";
import { CustomerService } from "src/app/services/customer.service";
import { ExchangeService } from "src/app/services/exchange.service";

@Component({
  selector: "app-customer-transactions-summary",
  templateUrl: "./customer-transactions-summary.component.html",
  styleUrls: ["./customer-transactions-summary.component.scss"],
})
export class CustomerTransactionsSummaryComponent implements OnInit {
  currentBranch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  filter: AccountTransactionFilter = {};
  transactions: AccountTransaction[] = [];
  Type = AccountTransactionType;
  Reason = AccountTransactionReason;
  operationDate = new Date();
  customer: Customer = {};
  filterType: string;
  exchangeId: number;
  exchanges: ExchangeItem[] = [];
  transactionIds: number[] = [];
  date = new Date();

  constructor(
    private route: ActivatedRoute,
    private accountTransactionService: AccountTransactionService,
    private customerService: CustomerService,
    private exchangeService: ExchangeService
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  getParams() {
    this.route.queryParams.subscribe((params) => {
      this.filter.customerId = parseInt(params.customerId);
      if (Array.isArray(params.id)){
          params.id.forEach(id => {
            this.transactionIds.push(Number(id))
          });
          this.getAccountTransactionsWithIds();
        }
      if (typeof params.id === 'string') {
        this.transactionIds.push(Number(params.id))
        this.getAccountTransactionsWithIds();
      }

      if (params.period != undefined)
        this.setPeriod(params.period);
      if (params.customerId != undefined)
        this.getCustomerBalance(params.customerId);


    });
  }

  getAccountTransactions() {
    this.accountTransactionService
      .customerTransactionsSummary({
        customerBranchId: this.currentBranch.id,
        customerId: this.filter.customerId,
        beginDate: this.filter.beginDate,
        endDate: this.filter.endDate,
      })
      .toPromise()
      .then((res) => {
        this.transactions = res.data.Items;
      });
  }

  getAccountTransactionsWithIds() {
    this.accountTransactionService
      .customerTransactionsSummaryWithIds({
        branchId: this.currentBranch.id,
        transactionIds: this.transactionIds,
        pageIndex: 1,
        itemPerPage: 100
      })
      .toPromise()
      .then((res) => {
        this.transactions = res.data.Items;
      });
  }

  setPeriod(period: string) {
    this.filter.beginDate = new Date();
    this.filter.endDate = new Date();
    this.filter.beginDate.setHours(0, 0, 1, 0);
    // this.filter.endDate.setHours(23,59,0,0)

    switch (period) {
      case "daily":
        this.filterType = "Günlük";
        break;
      case "weekly":
        this.filter.beginDate.setDate(this.filter.beginDate.getDate() - 7);
        this.filterType = "Haftalık";
        break;
      case "monthly":
        this.filter.beginDate.setMonth(this.filter.beginDate.getMonth() - 1);
        this.filterType = "Aylık";
        break;
      case "half-year":
        this.filter.beginDate.setMonth(this.filter.beginDate.getMonth() - 6);
        this.filterType = "6 Aylık";
        break;
      case "all":
        this.filter.beginDate.setFullYear(2020, 1, 1);
        this.filterType = "Tümü";
        break;
    }

    this.getAccountTransactions();
  }

  getCustomerBalance(customerId) {
    this.customerService
      .getBalanceByDate({
        id: customerId,
        branchId: this.currentBranch.id,
        endDate: this.filter.endDate,
        beginDate: this.filter.beginDate,
      })
      .toPromise()
      .then((res) => {
        // const _balanceList = res.data.mergedBalanceList.filter(p => p.value != 0)
        this.customer = res.data;
        // this.customer.mergedBalanceList = [];
        // this.customer.mergedBalanceList = _balanceList;
        setTimeout(()=> {
          window.onafterprint = window.close;
          window.print();
        }, 500);


      });
  }

  // getExchanges(ids: number[]){
  //   this.exchangeService.getByMultipleId(ids).toPromise().then(res => this.exchanges = res.data);
  // }
}
